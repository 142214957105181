/** @jsxImportSource @emotion/react */
import { useContext } from 'react';

import AddToCartSvg from '@stories/atoms/Icons/add-to-cart.svg';
import AddedToCartSvg from '@stories/atoms/Icons/added-to-cart.svg';
import { PageContext } from '@stories/templates/Context/pageContext';
import { useProductWithOptionsContext } from '@stories/utlis/context/ProductWithOptionsContext';

import useAddToCart from '../Button/useAddToCart';
import Spinner from '../Spinner';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';

export type AddToCartIconButtonProps = {
  productId: string;
  compact?: boolean;
  cssProps?: Interpolation<Theme>;
  disabled?: boolean;
  productType?: string;
  onClick?: React.MouseEventHandler;
};

const sizes = {
  default: {
    height: '42px',
    width: '60px',
    icon: '32px'
  },
  compact: {
    height: '38px',
    width: '55px',
    icon: '28px'
  }
};

export const AddToCartIconButton: React.FC<AddToCartIconButtonProps> = ({
  compact,
  cssProps,
  disabled,
  onClick,
  productId
}) => {
  const { showProductConfiguration } = useContext(PageContext);
  const { isLoading, isSuccess, handleAddProductClick } = useAddToCart();
  const buttonSize = compact ? 'compact' : 'default';
  const iconClass = { height: 'auto', width: sizes[buttonSize].icon } as const;
  const {
    baseprice,
    isProductConfigured,
    name,
    price,
    productConfiguration,
    productImage,
    productOptions,
    productType
  } = useProductWithOptionsContext();
  const numberOfOptions = productOptions.filter(({ selection }) => selection?.length > 1).length;

  return (
    <button
      data-add-to-cart-type={`product-${productOptions?.some(({ selection }) => selection?.length > 1) ? 'with-options' : 'without-options'}`}
      data-number-of-options={numberOfOptions}
      data-product-id={productId}
      aria-label="Add to cart"
      disabled={disabled}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
        if (productType !== 'simple' && showProductConfiguration) {
          showProductConfiguration({
            baseprice,
            name,
            price,
            productId,
            productImage,
            productOptions,
            productType
          });
        } else {
          handleAddProductClick({ productType, productConfiguration, productId, productImage });
        }
      }}
      type="button"
      css={(t) => ({
        ...t.button.secondary.solid,
        ...(isLoading && {
          ...t.button.secondary.solid[':hover'],
          pointerEvents: 'none'
        }),
        ...(isSuccess && {
          backgroundColor: 'green !important',
          borderColor: 'green !important'
        }),
        alignItems: 'center',
        borderRadius: '4px',
        color: 'white',
        display: 'flex',
        height: sizes[buttonSize].height,
        justifyContent: 'center',
        position: 'relative',
        width: sizes[buttonSize].width,
        zIndex: 1,
        ...(!isProductConfigured && { display: 'none' }),
        ...(cssProps as object)
      })}
    >
      {isLoading && <Spinner size="8px" />}
      {isSuccess && <AddedToCartSvg css={iconClass} />}
      {!(isLoading || isSuccess) && <AddToCartSvg css={iconClass} />}
    </button>
  );
};
